<template>
  <v-container fluid>
    <modal-base />
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/area_configuracaoAdmin" />
        </v-col>
      </v-row>
    </v-card-actions>
    <area-configuracao-campos
      :areas="areas"
      :configuracao="configuracao"
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import areaConfiguracao from "@/api/areaconfiguracao";
import areas from "@/api/empresas";

export default {
  components: {
    AreaConfiguracaoCampos: () => import("./AreaConfiguracaoCampos"),
  },

  data() {
    return {
      carregando: false,
      validacaoFormulario: {},
      configuracao: {},
      areas: [],
    };
  },

  mounted() {
    this.buscarAreas();
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await areaConfiguracao.buscar(this.$route.params.id);
        var configuracao = {
          area_id: resposta.data.data.area_id,
          adquirente: resposta.data.data.adquirente,
          cod_estabelecimento: resposta.data.data.cod_estabelecimento,
          cobrar_entrada: resposta.data.data.cobrar_entrada,
          layout_ticket: resposta.data.data.layout_ticket,
          layout_ticket_entrada: resposta.data.data.layout_ticket_entrada,
        };

        this.configuracao = configuracao;
      } catch (e) {
        this.$router.push("/area_configuracaoAdmin", () =>
          this.$snackbar.mostrar({
            cor: "error",
            mensagem: this.$erroApi._(e),
          })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(configuracao) {
      this.carregando = true;
      this.validacaoFormulario = {};
      configuracao.id = this.$route.params.id;

      try {
        await areaConfiguracao.atualizar(configuracao.id, configuracao);

        this.$router.push("/area_configuracaoAdmin", () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_editar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.carregando = false;
      }
    },

    async buscarAreas() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);

        const resposta = await areas.listarAreasEmpresa(
          this.$route.params.empresa
        );

        this.areas = resposta.data.data;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        console.log(e);
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>
